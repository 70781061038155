import "./App.css";
import { Fragment, useState, useEffect } from "react";
import { ThemeProvider, Box } from "@mui/material";
import { lightTheme, darkTheme } from "./theme";
import { Main } from "./routes.js/Main";
import { ToastProvider, AuthProvider } from "./context";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    console.log("🚀 ~ App version: 1.0.0.0.14, 14 June 2024");
  }, []);

  return (
    <Fragment>
      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <ToastProvider>
          <Router>
            <Main />
          </Router>
        </ToastProvider>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
